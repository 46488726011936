<template>
  <div class="main-bg content-container">
    <v-row justify="center" no-gutters>
      <v-col cols="5" md="10" lg="7">
        <v-tabs
          v-model="tab"
          fixed-tabs
          hide-slider
          height="42"
        >
          <v-tab
            v-for="tab in tabsItem"
            :key="tab.text"
            :class="{
              'border-left':  isQuestions(tab.text),
              'border-right': !isQuestions(tab.text)
            }"
            :disabled="getTabEditorDisable(tab.text)"
          >
            {{ tab.text }}
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item class="tab-content main-bg">
              <ContractTemplateQuestionnaire :questions="content.questionnaire.questions" />
            </v-tab-item>
            <v-tab-item class="tab-content main-bg">
              <div class="editor-container">
                <ContractTemplateEditor ref="editor" :questions="getEditorQuestions" />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
/* Components */
import ContractTemplateEditor from './Editor'
import ContractTemplateQuestionnaire from './Questionnaire'
/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'ContractTemplateContent',
  components: {
    ContractTemplateEditor,
    ContractTemplateQuestionnaire
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 0,
      tabsItem: [
        {
          text: 'Preguntas'
        },
        {
          text: 'Secciones'
        }
      ]
    }
  },

  watch: {
    tab (value) {
      this.$emit('tab', value)
    }
  },

  computed: {
    ...mapGetters('template', ['getEditorQuestions'])
  },

  created () {
    Bus.$on('reset-current-tab', () => {
      this.tab = 0
    })
  },

  methods: {
    isQuestions (text) {
      return text === 'Preguntas'
    },

    getTabEditorDisable (tab) {
      return !this.isQuestions(tab) && !this.content.questionnaire.isValid
    },

    toEditor () {
      this.tab = 1
    }
  }
}
</script>

<style lang="scss">

.content-container {
  .v-tabs {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .v-tab {
    opacity: 0.2;
    color: #fff !important;
    background: var(--v-primary-base);
    max-width: 100%;
  }

  .v-tab--active {
    opacity: 1;
    background: var(--v-primary-base);
  }

  .border-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .border-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0;
  }

  .tab-content {
    padding: 20px 0;
  }

  .editor-container {
    padding: 14px;
    height: 600px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    border-radius: 8px;
  }

  @media screen and (max-width: 1380px) {

  }
}

</style>
