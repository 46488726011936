const questionHints = {
  text: 'La respuesta a esta pregunta será texto abierto',
  list: 'La respuesta a esta pregunta será un selector de las opciones agregadas',
  number: 'Esta pregunta requiere una respuesta numérica',
  date: 'La respuesta a esta pregunta será para elegir una fecha'
}

export {
  questionHints
}
