<template>
  <div>
    <FullscreenDialog
      :is-activator-text="isText"
      :is-editing="isEditing"
      :id-template="idTemplate"
      :is-icon="isIcon"
      :is-creating-attach="isCreatingAttach"
      :is-creating-addendum="isCreatingAddendum"
      :contract-template="contractTemplate"
    >
      <template slot="activator-text">
        <slot name="text" />
      </template>
      <template slot="content" slot-scope="{close}">
        <div class="fill-height dialog-content">
          <v-row align="center" justify="space-around">
            <v-col md="auto">
              <v-btn icon ref="closeBtn" color="black"  @click="close">
                <img src="@/assets/icons/ic_keyboard_normal.svg" alt="">
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col md="2">
              <h4 class="dialog-title">{{ getHeaderTitle }}</h4>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-stepper v-model="stepper" light>
            <v-stepper-header>
              <v-row align="center" justify="center" no-gutters>
                <v-col cols="auto">
                  <v-stepper-step
                    :complete="stepper > 1"
                    :editable="stepper > 1"
                    step="1"
                    color="background"
                  >
                    1. Configuración
                  </v-stepper-step>
                </v-col>
                <v-col cols="auto">
                  <v-stepper-step
                    color="dark"
                    step="2"
                  >
                    2. Contenido
                  </v-stepper-step>
                </v-col>
              </v-row>
            </v-stepper-header>
            <v-stepper-content step="1">
              <ContractTemplateConfiguration
                :configuration-form="currentTemplate.configurationForm"
                :current-template="currentTemplate"
                :template="template"
                :isEditing="isEditing"
                :isCreatingAttach="isCreatingAttach"
                :isCreatingAddendum="isCreatingAddendum"
              />
            </v-stepper-content>
            <v-stepper-content step="2">
              <ContractTemplateContent @tab="updateTab" ref="content" :content="currentTemplate.content"/>
            </v-stepper-content>
          </v-stepper>
          <Footer>
            <template slot="footer-content">
                <div v-if="stepper === 2">
                  <div v-if="currentTab === 0" class="footer-content__buttons">
                    <v-row justify="space-between" align="center">
                      <v-col cols="auto">
                        <v-btn text color="primary" class="add-question mt-3" @click="addQuestion">+ Agregar pregunta</v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn large class="default-btn" color="primary"  @click="$refs.content.toEditor()" :disabled="getEditorDisabled">Siguiente</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-row justify="center" align="center">
                      <v-col cols="auto">
                        <v-btn large class="default-btn" color="primary"  @click="dialogVisualization = true">Previsualizar</v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn large class="default-btn" color="primary"  @click="saveTemplate()" :disabled="isSaving" :loading="isSaving">Guardar</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div v-else>
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-btn large class="default-btn" color="primary"  @click="stepper = 2" :disabled="getIsValidForm">Siguiente</v-btn>
                    </v-col>
                  </v-row>
                </div>
            </template>
          </Footer>
        </div>
      </template>
    </FullscreenDialog>

    <!-- Visualization Dialog -->
    <ContractTemplateVisualizationDialog :dialog="dialogVisualization" @close="dialogVisualization = false">
      <template slot="content">
        <div v-html="getVisualizationContent" />
      </template>
    </ContractTemplateVisualizationDialog>
  </div>
</template>

<script>

/* Components */
import FullscreenDialog from '@/components/Base/FullscreenDialog'
import Footer from '@/components/Base/Footer'
import ContractTemplateConfiguration from './Configuration'
import ContractTemplateContent from './Content'
import ContractTemplateVisualizationDialog from './VisualizationDialog'

/* Vuex */
import { mapActions, mapMutations, mapState } from 'vuex'

/* Utils */
import { getEmptyTemplate } from '@/utils/emptyTemplate'
import { colors } from '@/utils/colors'

/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'ContractTemplateCreationDialog',
  components: {
    FullscreenDialog,
    ContractTemplateConfiguration,
    ContractTemplateContent,
    ContractTemplateVisualizationDialog,
    Footer
  },

  props: {
    isAttached: {
      type: Boolean,
      default: () => false
    },
    isAddendum: {
      type: Boolean,
      default: () => false
    },
    isEditing: {
      type: Boolean,
      default: () => false
    },
    isCreatingAttach: {
      type: Boolean,
      default: () => false
    },
    isCreatingAddendum: {
      type: Boolean,
      default: () => false
    },
    idTemplate: {
      type: String,
      default: () => null
    },
    contractTemplate: {
      type: Object,
      default: () => null
    },
    template: {
      type: Object,
      default: () => null
    },
    isText: {
      type: Boolean,
      default: () => false
    },
    isIcon: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      stepper: 1,
      currentTab: 0,
      isSaving: false,
      dialogVisualization: false
    }
  },

  computed: {
    ...mapState('template', [
      'currentTemplate'
    ]),
    getIsValidForm () {
      return !this.currentTemplate.configurationForm.isValid
    },

    getHeaderTitle () {
      const prefix = this.isEditing ? 'Editar' : 'Crear'
      const text = this.isAttached ? 'Anexo' : 'Plantilla'
      return `${prefix} ${text}`
    },

    getEditorDisabled () {
      return !this.currentTemplate.content.questionnaire.isValid
    },

    getVisualizationContent () {
      return this.currentTemplate.content.editorContent
    }
  },

  created () {
    Bus.$on('reset-stepper', () => {
      this.stepper = 1
    })
  },

  methods: {
    ...mapMutations({
      setSnackbar: 'SET_SNACKBAR'
    }),
    ...mapMutations('template', {
      setCurrentTemplate: 'SET_CURRENT_TEMPLATE',
      addQuestion: 'ADD_QUESTION'
    }),
    ...mapActions('template', [
      'saveContractTemplate',
      'editContractTemplate'
    ]),

    updateTab (data) {
      this.currentTab = data
    },

    saveTemplate () {
      this.isSaving = true
      let request = {}

      const deletedQuestions = this.currentTemplate.deletedQuestions.map(deletedQuestion => {
        return deletedQuestion.idquestion
      })

      const deleteAnswerOptions = this.currentTemplate.deletedOptions.map(deletedOption => {
        return deletedOption.idanswer
      })

      if (this.contractTemplate) {
        request = {
          title: this.currentTemplate.configurationForm.templateName,
          content: this.currentTemplate.content.editorContent,
          attached: this.isAttached,
          idtemplate: this.idTemplate,
          country: this.currentTemplate.configurationForm.templateCountry,
          templateType: this.currentTemplate.configurationForm.typeTemplate,
          questions: this.currentTemplate.content.questionnaire.questions,
          addendum: this.isAddendum,
          idcontract: this.contractTemplate.idcontract,
          deleteQuestions: deletedQuestions,
          deleteAnswerOptions: deleteAnswerOptions
        }
      } else {
        request = {
          title: this.currentTemplate.configurationForm.templateName,
          content: this.currentTemplate.content.editorContent,
          attached: this.isAttached,
          idtemplate: this.idTemplate,
          country: this.currentTemplate.configurationForm.templateCountry,
          templateType: this.currentTemplate.configurationForm.typeTemplate,
          questions: this.currentTemplate.content.questionnaire.questions,
          addendum: this.isAddendum,
          deleteQuestions: deletedQuestions,
          deleteAnswerOptions: deleteAnswerOptions
        }
      }

      if (this.isEditing) {
        this.editContractTemplate(request)
          .then(response => {
            this.isSaving = false
            this.setCurrentTemplate(getEmptyTemplate())
            this.stepper = 1
            Bus.$emit('close-creation-dialog')
            this.setSnackbar({
              text: 'Petición realizada exitosamente',
              timeout: 3000,
              showing: true,
              color: colors.success
            })
          })
          .catch(error => {
            this.setSnackbar({
              text: error.response.data.detail,
              timeout: 3000,
              showing: true,
              color: colors.primary
            })
            this.isSaving = false
          })
      } else {
        this.saveContractTemplate(request)
          .then(response => {
            this.isSaving = false
            this.setCurrentTemplate(getEmptyTemplate())
            const idTemplate = response.data.template

            if (this.isAttached || this.isAddendum) {
              Bus.$emit('close-creation-dialog')
            } else {
              this.$router.push(`/portal/templates/${idTemplate}`)
            }
            this.stepper = 1
            this.setSnackbar({
              text: 'Petición realizada exitosamente',
              timeout: 3000,
              showing: true,
              color: colors.success
            })
          })
          .catch(error => {
            this.setSnackbar({
              text: error.response.data.detail,
              timeout: 3000,
              showing: true,
              color: colors.primary
            })
            this.isSaving = false
          })
      }
    }
  }
}
</script>

<style lang="scss">

.dialog-content {
  background-color: var(--v-background-base);
  padding: 30px 30px 0 30px;

  .dialog-title {
    margin-left: 20px;
  }

  .v-tab {
    text-transform: none;
    font-weight: 500;
    font-size: 20px;
  }

  .v-stepper {
    box-shadow: none;
    padding: 0 !important;
    overflow-y: auto;
    background: var(--v-background-base);
  }

  .v-stepper__header {
    background: var(--v-background-base);
    height: max-content;
  }

  .v-stepper__content {
    padding: 0;
    margin: 0;
    background: var(--v-background-base);
  }

  .v-stepper__step {
    .v-stepper__label {
      font-size: 20px;
      font-weight: 500;
      color: gray;
      line-height: 150%;
      text-shadow: none !important;
    }

    .v-stepper__step__step {
      display: none;
    }
  }

  .v-stepper__step--editable.v-stepper__step--complete {
    .v-stepper__label {
      color: gray !important;
    }
  }

  .v-stepper__step--editable:hover {
    background: transparent !important;
  }

  .v-stepper__step--active {
    .v-stepper__label {
      color: var(--v-dark-base);
    }
  }
}

@media screen and (max-width: 1380px) {
  .dialog-content {
    padding: 5px 12px;
    .v-stepper__header {
      max-height: max-content;
    }

    .v-stepper__step {
      .v-stepper__label {
        font-size: 18px;
        height: 20px;
      }
    }

    .v-tab {
      font-size: 18px;
    }

    .v-stepper__content {
      margin-bottom: 60px;
    }
  }
}
</style>
