import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"editor"},[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menubar"},[_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.bold() },attrs:{"icon":""},on:{"click":commands.bold}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"bold"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Negrita ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.italic() },attrs:{"icon":""},on:{"click":commands.italic}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"italic"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Cursiva ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.strike() },attrs:{"icon":""},on:{"click":commands.strike}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"strike"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Tachado ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.underline() },attrs:{"icon":""},on:{"click":commands.underline}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"underline"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Subrayado ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.code() },attrs:{"icon":""},on:{"click":commands.code}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"code"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Código ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.paragraph() },attrs:{"icon":""},on:{"click":commands.paragraph}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"paragraph"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Párrafo ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.heading({ level: 1 }) },attrs:{"icon":""},on:{"click":function($event){return commands.heading({ level: 1 })}}},'button',attrs,false),on),[_c('span',{staticClass:"heading-font"},[_vm._v("H1")])])]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Encabezado 1 ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.heading({ level: 2 }) },attrs:{"icon":""},on:{"click":function($event){return commands.heading({ level: 2 })}}},'button',attrs,false),on),[_c('span',{staticClass:"heading-font"},[_vm._v("H2")])])]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Encabezado 2 ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.heading({ level: 3 }) },attrs:{"icon":""},on:{"click":function($event){return commands.heading({ level: 3 })}}},'button',attrs,false),on),[_c('span',{staticClass:"heading-font"},[_vm._v("H3")])])]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Encabezado 3 ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': isActive.bullet_list() },attrs:{"icon":""},on:{"click":commands.bullet_list}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"ul"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Lista con viñetas ")])]),_c(VMenu,{staticClass:"editor-menu",attrs:{"bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button"},'button',attrs,false),on),[_c('icon',{attrs:{"name":"ol"}})],1)]}}],null,true)},[_c(VList,{staticClass:"editor-menu__list",attrs:{"elevation":"0"}},[_c(VListItem,{staticClass:"list-item text-center",attrs:{"dense":""}},[_c(VTooltip,{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({class:{ 'is-active': isActive.ordered_list() },on:{"click":function($event){return commands.ordered_list()}}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"ol","height":16,"width":16}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Lista numerada ")])])],1),_c(VListItem,{staticClass:"list-item text-center",attrs:{"dense":""}},[_c(VTooltip,{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({class:{ 'is-active': isActive.ordered_list_letter() },on:{"click":function($event){return commands.ordered_list_letter()}}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"ic_format_list_letters","height":16,"width":16}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Lista alfabética ")])])],1),_c(VListItem,{staticClass:"list-item text-center",attrs:{"dense":""}},[_c(VTooltip,{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({class:{ 'is-active': isActive.ordered_list_roman() },on:{"click":function($event){return commands.ordered_list_roman()}}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"ic_format_list_roman_numerals","height":16,"width":16}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Lista romana ")])])],1)],1)],1),_c(VMenu,{staticClass:"editor-menu",attrs:{"bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button"},'button',attrs,false),on),[_c('icon',{attrs:{"name":_vm.activeAlignment.icon,"height":16,"width":16}})],1)]}}],null,true)},[_c(VList,{staticClass:"editor-menu__list",attrs:{"elevation":"0"}},_vm._l((_vm.alignmentItems),function(alignment){return _c(VListItem,{key:alignment.align,staticClass:"list-item text-center",attrs:{"dense":""}},[_c(VTooltip,{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.activeMarkAttrs.aligntext.align === alignment.align },on:{"click":function($event){return commands.aligntext({ align: alignment.align })}}},'button',attrs,false),on),[_c('icon',{attrs:{"name":alignment.icon,"height":16,"width":16}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" "+_vm._s(alignment.text)+" ")])])],1)}),1)],1),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",attrs:{"icon":""},on:{"click":commands.horizontal_rule}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"hr"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Sepador ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",attrs:{"icon":""},on:{"click":commands.undo}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"undo"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Deshacer ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",attrs:{"icon":""},on:{"click":commands.redo}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"redo"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Rehacer ")])]),_c(VDialog,{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var uploadDialog = ref.on;
return [_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",attrs:{"dark":""}},'button',attrs,false),Object.assign({}, tooltip, uploadDialog)),[_c('icon',{attrs:{"name":"word","height":15,"width":15}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Subir archivo ")])])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"pa-4",attrs:{"elevation":"2","max-width":"400","height":"180"}},[_c('FileUploader',{ref:"uploader",attrs:{"extension":"docx, doc","multiple":false},on:{"upload-file":function($event){return _vm.uploadFile($event)}}})],1)],1),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",attrs:{"icon":""},on:{"click":_vm.clearEditor}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"trash","height":14,"width":14}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Borrar todo ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":function($event){return commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })}}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"table"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Insertar tabla ")])]),(isActive.table())?_c('span',[_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":commands.deleteTable}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"delete_table"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Eliminar tabla ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":commands.addColumnBefore}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"add_col_before"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Insertar columna a la izquiera ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":commands.addColumnAfter}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"add_col_after"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Insertar columna a la derecha ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":commands.deleteColumn}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"delete_col"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Eliminar Columna ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":commands.addRowBefore}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"add_row_before"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Insertar fila arriba ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":commands.addRowAfter}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"add_row_after"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Insertar fila debajo ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":commands.deleteRow}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"delete_row"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Eliminar fila ")])]),_c(VTooltip,{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":commands.toggleCellMerge}},'button',attrs,false),on),[_c('icon',{attrs:{"name":"combine_cells"}})],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-font"},[_vm._v(" Combinar celdas ")])])],1):_vm._e()],1)]}}])}),_c('editor-content',{staticClass:"editor__content content-editor",attrs:{"editor":_vm.editor}})],1),_c('p',{staticClass:"help"},[_vm._v("Para conectar una pregunta con opciones de respuesta lógica escriba @")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSuggestions),expression:"showSuggestions"}],ref:"suggestions",staticClass:"suggestion-list"},[(_vm.hasResults)?_vm._l((_vm.filteredUsers),function(user,index){return _c('div',{key:user.id,staticClass:"suggestion-list__item",class:{ 'is-selected': _vm.navigatedUserIndex === index },on:{"click":function($event){return _vm.selectUser(user)}}},[_vm._v(" "+_vm._s(user.name)+" ")])}):_c('div',{staticClass:"suggestion-list__item is-empty"},[_vm._v(" No hay preguntas asociadas ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }