<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      @keydown.esc="closeDialog"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <div class="visualization-container">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn fab small depressed color="gray" @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <br>
          <slot name="content" />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ContractTemplateVisualizationDialog',

  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style>

.visualization-container {
  margin: 0;
  padding: 20px 10%;
}

</style>
