import { Node } from 'tiptap'
import { wrappingInputRule } from 'tiptap-commands'
import toggleList from './toggleList'

export default class OrderedListRoman extends Node {
  get name () {
    return 'ordered_list_roman'
  }

  get schema () {
    return {
      attrs: {
        order: {
          default: 1
        },
        type: {
          default: 'i'
        }
      },
      content: 'list_item+',
      group: 'block',
      parseDOM: [
        {
          tag: 'ol',
          getAttrs: dom => ({
            order: dom.hasAttribute('start') ? +dom.getAttribute('start') : 1
          })
        }
      ],
      toDOM: node => ['ol', { type: node.attrs.type }, 0]
    }
  }

  commands ({ type, schema }) {
    return () => toggleList(type, schema.nodes.list_item)
  }

  keys ({ type, schema }) {
    return {
      'Shift-Ctrl-9': toggleList(type, schema.nodes.list_item)
    }
  }

  inputRules ({ type }) {
    return [
      wrappingInputRule(
        /^(\d+)\.\s$/,
        type,
        match => ({ order: +match[1] }),
        (match, node) => node.childCount + node.attrs.order === +match[1]
      )
    ]
  }
}
