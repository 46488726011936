<template>
  <div class="main-bg">
    <v-row justify="center">
      <v-col cols="5">
        <v-form ref="configurationFormRef" v-model="configurationForm.isValid">
          <label class="form-label" for="name">
            Nombre
          </label>
          <v-text-field
            class="form-field mt-2"
            :rules="templateNameRules"
            name="name"
            outlined
            single-line
            background-color="white"
            v-model="configurationForm.templateName"
          />
          <label class="form-label" for="type">
            Elige la categoría
          </label>
          <v-select
            class="form-field mt-2"
            :rules="typeTemplateRules"
            name="type"
            :items="templateTypes"
            item-text="name"
            item-value="id"
            outlined
            single-line
            background-color="white"
            v-model="configurationForm.typeTemplate"
            :disabled="disableFromEdit()"
            clearable
          />
          <label class="form-label" for="type">
            País
          </label>
          <v-select
            class="form-field mt-2"
            :rules="countryTemplateRules"
            name="type"
            :items="countries"
            item-text="name"
            item-value="country_code"
            outlined
            single-line
            background-color="white"
            v-model="configurationForm.templateCountry"
            :disabled="disableFromEdit()"
            clearable
          />
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Bus from '@/helpers/bus'

export default {
  name: 'ContractTemplateConfiguration',

  props: {
    configurationForm: {
      type: Object,
      required: true
    },
    currentTemplate: {
      type: Object,
      default: () => {}
    },
    template: {
      type: Object,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: () => false
    },
    isCreatingAttach: {
      type: Boolean,
      default: () => false
    },
    isCreatingAddendum: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      templateNameRules: [
        v => !!v || 'El nombre de la plantilla es requerido'
      ],
      typeTemplateRules: [
        v => !!v || 'El tipo de plantilla es requerido'
      ],
      countryTemplateRules: [
        v => !!v || 'El país de la plantilla es requerido'
      ],
      editFromTemplate: false
    }
  },

  created () {
    Bus.$on('reset-configuration-form', () => {
      if (this.$refs.configurationFormRef) {
        this.$refs.configurationFormRef.resetValidation()
      }
    })
    // Se valida si el form que llega es anexo o plantilla
    if (this.configurationForm.isValid) {
      this.editFromTemplate = true
    } else {
      this.editFromTemplate = false
    }
  },

  computed: {
    ...mapState(['countries', 'templateTypes'])
  },

  methods: {
    disableFromEdit () {
      if (this.isCreatingAttach || this.isCreatingAddendum) {
        return true
      } else if (!this.isEditing) {
        return false
      } else if (this.template != null) {
        if (this.template.contracts > 0) {
          return true
        }
      } else {
        return this.configurationForm.isValid && this.$route.params.id !== this.currentTemplate.idTemplate
      }
    }
  }
}
</script>

<style>

.configuration-container {
  padding: 60px 0;
}

</style>
