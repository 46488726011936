<template>
  <div class="detail-container fill-height" v-if="!isLoading">
    <v-row align="center" justify="center">
      <v-col md="auto">
        <v-btn icon color="black"  to="/portal/templates" exact>
          <img src="@/assets/icons/ic_keyboard_normal.svg" alt="">
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <p class="detail-header mb-0">
          Plantilla, {{ template.template.contracts }} contratos generados
        </p>
        <p class="mt-0 description">
          {{ description }}
        </p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="mr-15" v-if="template.template.published">
        <ContractGenerationDialog :generateFromTemplate="true" :template="this.template">
          <template slot="text">
            Usar Plantilla
          </template>
        </ContractGenerationDialog>
      </v-col>
    </v-row>
    <div class="detail-content px-2">
      <v-row no-gutters>
        <v-col>
          <v-row align="center">
            <v-col cols="auto">
              {{ template.template.title }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="black" v-bind="attrs" v-on="on" @click="getVisualizationContent(id)">
                    <img src="@/assets/icons/eye.svg" height="20" width="20">
                  </v-btn>
                </template>
                <span>Previsualizar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="black" v-bind="attrs" v-on="on" @click="convertFile(id)" exact>
                    <img src="@/assets/icons/download.svg" height="20" width="20">
                  </v-btn>
                </template>
                <span>Descargar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto" v-if="isLegalProfile()">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <ContractTemplateCreationDialog
                    :is-icon="true"
                    :is-editing="true"
                    :id-template="id"
                    :template="template.template"
                  >
                    <template slot="text">
                      <img v-bind="attrs" v-on="on" src="@/assets/icons/edit.svg" height="20" width="20">
                    </template>
                  </ContractTemplateCreationDialog>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto" v-if="isLegalProfile()">
              <v-tooltip v-if="template.template.published" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="black" v-bind="attrs" v-on="on" @click="publicToggle(id, template.template.published)" exact>
                    <img src="@/assets/icons/public_on.svg" height="20" width="20">
                  </v-btn>
                </template>
                <span>Ocultar</span>
              </v-tooltip>
              <v-tooltip v-else top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="black" v-bind="attrs" v-on="on" @click="publicToggle(id, template.template.published)" exact>
                    <img src="@/assets/icons/public_off.svg" height="20" width="20">
                  </v-btn>
                </template>
                <span>Publicar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    exact
                    v-on="on"
                    v-bind="attrs"
                    color="black"
                    @click="historyFunction(template.template)"
                  >
                    <v-icon
                    >mdi-history</v-icon>
                  </v-btn>
                </template>
                <span>Historial</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="detail-attached">
      <p class="subtitle-1 attached-header">Anexos</p>
      <div v-for="attachment in template.attachments" :key="attachment.title">
        <v-row no-gutters class="detail-attached-header">
          <v-col>
            <v-row align="center">
              <v-col cols="auto">
                {{ attachment.title }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on" @click="getVisualizationContent(attachment.idtemplate)">
                      <img src="@/assets/icons/eye.svg" height="20" width="20">
                    </v-btn>
                  </template>
                  <span>Previsualizar</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on" @click="convertFile(attachment.idtemplate)" exact>
                      <img src="@/assets/icons/download.svg" height="20" width="20">
                    </v-btn>
                  </template>
                  <span>Descargar</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto" v-if="isLegalProfile()">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <ContractTemplateCreationDialog :is-icon="true" :is-editing="true" :is-attached="true" :id-template="attachment.idtemplate.toString()">
                      <template slot="text">
                        <img v-bind="attrs" v-on="on" src="@/assets/icons/edit.svg" height="20" width="20">
                      </template>
                    </ContractTemplateCreationDialog>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto" v-if="isLegalProfile()">
                <v-tooltip v-if="attachment.published" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on" @click="publicToggle(attachment.idtemplate, attachment.published )" exact>
                      <img src="@/assets/icons/public_on.svg" height="20" width="20">
                    </v-btn>
                  </template>
                  <span>Ocultar</span>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on" @click="publicToggle(attachment.idtemplate, attachment.published )" exact>
                      <img src="@/assets/icons/public_off.svg" height="20" width="20">
                    </v-btn>
                  </template>
                  <span>Publicar</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    exact
                    v-on="on"
                    v-bind="attrs"
                    color="black"
                    @click="historyFunction(attachment)"
                  >
                    <v-icon
                    >mdi-history</v-icon>
                  </v-btn>
                </template>
                <span>Historial</span>
              </v-tooltip>
            </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <ContractTemplateCreationDialog
        v-if="isLegalProfile()"
        :is-text="true"
        :is-attached="true"
        :contract-template="template.template"
        :is-creating-attach="true"
      >
        <template slot="text">
          + Agregar anexos
        </template>
      </ContractTemplateCreationDialog>
    </div>

    <div class="detail-addendum">
      <p class="subtitle-1 addendum-header">Adendas</p>
      <div v-for="addendum in template.addendums" :key="addendum.title">
        <v-row no-gutters class="detail-addendum-header">
          <v-col>
            <v-row align="center">
              <v-col cols="auto">
                {{ addendum.title }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on" @click="getVisualizationContent(addendum.idtemplate)">
                      <img src="@/assets/icons/eye.svg" height="20" width="20">
                    </v-btn>
                  </template>
                  <span>Previsualizar</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on" @click="convertFile(addendum.idtemplate)" exact>
                      <img src="@/assets/icons/download.svg" height="20" width="20">
                    </v-btn>
                  </template>
                  <span>Descargar</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto" v-if="isLegalProfile()">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <ContractTemplateCreationDialog :is-icon="true" :is-editing="true" :is-addendum="true" :id-template="addendum.idtemplate.toString()">
                      <template slot="text">
                        <img v-bind="attrs" v-on="on" src="@/assets/icons/edit.svg" height="20" width="20">
                      </template>
                    </ContractTemplateCreationDialog>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto" v-if="isLegalProfile()">
                <v-tooltip v-if="addendum.published" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on" @click="publicToggle(addendum.idtemplate, addendum.published )" exact>
                      <img src="@/assets/icons/public_on.svg" height="20" width="20">
                    </v-btn>
                  </template>
                  <span>Ocultar</span>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on" @click="publicToggle(addendum.idtemplate, addendum.published )" exact>
                      <img src="@/assets/icons/public_off.svg" height="20" width="20">
                    </v-btn>
                  </template>
                  <span>Publicar</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    exact
                    v-on="on"
                    v-bind="attrs"
                    color="black"
                    @click="historyFunction(addendum)"
                  >
                    <v-icon
                    >mdi-history</v-icon>
                  </v-btn>
                </template>
                <span>Historial</span>
              </v-tooltip>
            </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <ContractTemplateCreationDialog
        v-if="isLegalProfile()"
        :is-text="true"
        :is-addendum="true"
        :contract-template="template.template"
        :is-creating-attach="true"
      >
        <template slot="text">
          + Agregar adendas
        </template>
      </ContractTemplateCreationDialog>
    </div>

    <!-- Visualization Dialog -->
    <ContractTemplateVisualizationDialog :dialog="dialogVisualization" @close="dialogVisualization = false">
      <template slot="content">
        <div v-html="currentVisualization" />
      </template>
    </ContractTemplateVisualizationDialog>

    <!-- History Nav drawer -->
    <HistoryNavegationDrawer
      ref="refHistory"
      @update-show-navegation-drawer="showNavegationDrawer = false"
    />
  </div>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Bus from '@/helpers/bus'
import { colors } from '@/utils/colors'

/* Components */
import ContractTemplateVisualizationDialog from './VisualizationDialog'
import ContractTemplateCreationDialog from './CreationDialog'
import ContractGenerationDialog from '@/components/Contract/GenerationDialog'
import Loader from '@/components/Base/Loader'
import HistoryNavegationDrawer from '@/components/ContractTemplate/History'

/* Mixins */
import permissionValidate from '@/mixins/permissionValidate'

export default {
  name: 'ContractTemplateDetail',
  components: {
    ContractTemplateVisualizationDialog,
    ContractTemplateCreationDialog,
    ContractGenerationDialog,
    Loader,
    HistoryNavegationDrawer
  },

  mixins: [permissionValidate],

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      template: null,
      dialogVisualization: false,
      currentVisualization: '',
      isLoading: false,
      typeTemplate: '',
      showNavegationDrawer: false,
      historyId: 0
    }
  },

  computed: {
    ...mapGetters('template', [
      'getTemplateById'
    ]),

    ...mapGetters(['getTypeById']),

    description () {
      return `${this.typeTemplate.name} - Creado por ${this.template.template.userCreated} el ${this.template.template.created}`
    },

    haveAttached () {
      return this.template.attachments.length > 0
    }
  },

  created () {
    this.getCurrentDetail()
    Bus.$on('change-detail-template', () => {
      this.getCurrentDetail()
    })
  },

  methods: {
    ...mapMutations({
      setSnackbar: 'SET_SNACKBAR'
    }),
    ...mapActions('template', [
      'getTemplateDetail',
      'getTemplate',
      'convertToWordTemplate',
      'editPublishedContractTemplate'
    ]),

    getCurrentDetail () {
      const params = {
        idtemplate: this.id
      }
      this.isLoading = true
      this.getTemplateDetail(params)
        .then(response => {
          this.template = response.data
          this.isLoading = false
          this.typeTemplate = this.getTypeById(this.template.template.templateType)
        })
        .catch(error => {
          console.log(`Detail.vue -> getCurrentDetail() -> ${error}`)
          console.log(error.response)
          this.isLoading = false
          // redirect to home
          this.$router.push({ path: '/portal/templates' })
        })
    },
    getVisualizationContent (idTemplate) {
      const params = {
        template_id: idTemplate
      }
      this.getTemplate(params)
        .then(response => {
          this.currentVisualization = response.data.content
          this.dialogVisualization = true
        })
        .catch(error => {
          console.log(error)
        })
    },

    publicToggle (id, published) {
      const data = {
        id: id,
        data: {
          published: !published
        }
      }

      this.editPublishedContractTemplate(data)
        .then(response => {
          this.getCurrentDetail()
          this.setSnackbar({
            text: 'Petición realizada exitosamente',
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
    },

    convertFile (id) {
      const params = {
        iddocument: id,
        document: 0
      }

      this.convertToWordTemplate(params)
        .then(response => {
          const filename = response.headers['content-disposition'].split('=')[1]
          const file = new Blob([response.data])
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.setAttribute('download', filename)
          link.click()
          this.setSnackbar({
            text: 'Descarga realizada exitosamente',
            timeout: 3000,
            showing: true,
            color: colors.success
          })
        })
    },

    historyFunction (item) {
      this.$refs.refHistory.callFromParent(item.idtemplate)
      // this.showNavegationDrawer = true
    }
  }
}
</script>

<style lang="scss">

.detail-container {
  padding: 20px 30px;

  .detail-header {
    font-size: 24px;
    font-weight: 500;
    color: var(--v-dark-base);
  }

  .description {
    font-size: 14px;
    font-weight: normal;
  }

  .detail-content {
    margin: 0 60px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  }

  .detail-attached {
    margin: 10px 60px;
    color: var(--v-dark-base);
  }

  .add-attached {
    text-transform: none;
    margin: 0 !important;
    padding: 0 !important;
  }

  .attached-header {
    font-size: 24px;
    font-weight: 500;
    padding-left: 2px;
    color: var(--v-dark-base);
  }

  .detail-attached-header {
    padding: 0 10px;
    margin-bottom: 15px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  }

  .addendum-header {
    font-size: 24px;
    font-weight: 500;
    padding-left: 2px;
    color: var(--v-dark-base);
  }

  .detail-addendum {
    margin: 10px 60px;
    color: var(--v-dark-base);
  }

  .add-addendum {
    text-transform: none;
    margin: 0 !important;
    padding: 0 !important;
  }

  .detail-addendum-header {
    padding: 0 10px;
    margin-bottom: 15px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  }

  .addendum-header {
    font-size: 24px;
    font-weight: 500;
    padding-left: 2px;
    color: var(--v-dark-base);
  }
}

</style>
