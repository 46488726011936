import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog($event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"tile":""}},[_c('div',{staticClass:"visualization-container"},[_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"fab":"","small":"","depressed":"","color":"gray"},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c('br'),_vm._t("content")],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }