<template>
  <div class="main">
    <div class="editor">
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <div class="menubar">
          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                class="menubar__button"
                icon
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="bold" />
              </button>
            </template>
            <span class="tooltip-font">
              Negrita
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                class="menubar__button"
                icon
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="italic" />
              </button>
            </template>
            <span class="tooltip-font">
              Cursiva
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                :class="{ 'is-active': isActive.strike() }"
                @click="commands.strike"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="strike" />
              </button>
            </template>
            <span class="tooltip-font">
              Tachado
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="underline" />
              </button>
            </template>
            <span class="tooltip-font">
              Subrayado
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                :class="{ 'is-active': isActive.code() }"
                @click="commands.code"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="code" />
              </button>
            </template>
            <span class="tooltip-font">
              Código
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                :class="{ 'is-active': isActive.paragraph() }"
                @click="commands.paragraph"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="paragraph" />
              </button>
            </template>
            <span class="tooltip-font">
              Párrafo
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                @click="commands.heading({ level: 1 })"
                v-bind="attrs"
                v-on="on"
              >
                <span class="heading-font">H1</span>
              </button>
            </template>
            <span class="tooltip-font">
              Encabezado 1
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                @click="commands.heading({ level: 2 })"
                v-bind="attrs"
                v-on="on"
              >
                <span class="heading-font">H2</span>
              </button>
            </template>
            <span class="tooltip-font">
              Encabezado 2
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                @click="commands.heading({ level: 3 })"
                v-bind="attrs"
                v-on="on"
              >
                <span class="heading-font">H3</span>
              </button>
            </template>
            <span class="tooltip-font">
              Encabezado 3
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                :class="{ 'is-active': isActive.bullet_list() }"
                @click="commands.bullet_list"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="ul" />
              </button>
            </template>
            <span class="tooltip-font">
              Lista con viñetas
            </span>
          </v-tooltip>

          <v-menu bottom open-on-hover class="editor-menu">
            <template v-slot:activator="{ on, attrs }">
              <button
                v-on="on"
                v-bind="attrs"
                class="menubar__button"
              >
                <icon name="ol" />
              </button>
            </template>
            <v-list elevation="0" class="editor-menu__list">
              <v-list-item dense class="list-item text-center">
                <v-tooltip
                  right
                  color="black"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <icon name="ol" :height="16" :width="16"/>
                    </button>
                  </template>
                  <span class="tooltip-font">
                    Lista numerada
                  </span>
                </v-tooltip>
              </v-list-item>
              <v-list-item dense class="list-item text-center">
                <v-tooltip
                  right
                  color="black"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      :class="{ 'is-active': isActive.ordered_list_letter() }"
                      @click="commands.ordered_list_letter()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <icon name="ic_format_list_letters" :height="16" :width="16" />
                    </button>
                  </template>
                  <span class="tooltip-font">
                    Lista alfabética
                  </span>
                </v-tooltip>
              </v-list-item>
              <v-list-item dense class="list-item text-center">
                <v-tooltip
                  right
                  color="black"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      :class="{ 'is-active': isActive.ordered_list_roman() }"
                      @click="commands.ordered_list_roman()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <icon name="ic_format_list_roman_numerals" :height="16" :width="16"/>
                    </button>
                  </template>
                  <span class="tooltip-font">
                    Lista romana
                  </span>
                </v-tooltip>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu bottom open-on-hover class="editor-menu">
            <template v-slot:activator="{ on, attrs }">
              <button
                v-on="on"
                v-bind="attrs"
                class="menubar__button"
              >
                <icon :name="activeAlignment.icon" :height="16" :width="16" />
              </button>
            </template>
            <v-list elevation="0" class="editor-menu__list">
              <v-list-item dense v-for="alignment in alignmentItems" :key="alignment.align" class="list-item text-center">
                <v-tooltip
                  right
                  color="black"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      :class="{ 'is-active': editor.activeMarkAttrs.aligntext.align === alignment.align }"
                      @click="commands.aligntext({ align: alignment.align })"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <icon :name="alignment.icon" :height="16" :width="16" />
                    </button>
                  </template>
                  <span class="tooltip-font">
                    {{alignment.text}}
                  </span>
                </v-tooltip>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                @click="commands.horizontal_rule"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="hr" />
              </button>
            </template>
            <span class="tooltip-font">
              Sepador
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                @click="commands.undo"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="undo" />
              </button>
            </template>
            <span class="tooltip-font">
              Deshacer
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                @click="commands.redo"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="redo" />
              </button>
            </template>
            <span class="tooltip-font">
              Rehacer
            </span>
          </v-tooltip>

          <v-dialog
            v-model="dialog"
            max-width="400"
          >
            <template v-slot:activator="{ on: uploadDialog }">
              <v-tooltip
                bottom
                color="black"
              >
              <template v-slot:activator="{ on: tooltip, attrs }">
                <button
                  class="menubar__button"
                  dark
                  v-bind="attrs"
                  v-on="{...tooltip, ...uploadDialog}"
                >
                  <icon name="word" :height="15" :width="15" />
                </button>
              </template>
              <span class="tooltip-font">
                Subir archivo
              </span>
              </v-tooltip>
            </template>
            <v-card class="pa-4" elevation="2" max-width="400" height="180">
              <FileUploader ref="uploader" extension="docx, doc" :multiple="false" @upload-file="uploadFile($event)"/>
            </v-card>
          </v-dialog>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                icon
                class="menubar__button"
                @click="clearEditor"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="trash" :height="14" :width="14" />
              </button>
            </template>
            <span class="tooltip-font">
              Borrar todo
            </span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                class="menubar__button"
                @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="table" />
              </button>
            </template>
            <span class="tooltip-font">
              Insertar tabla
            </span>
          </v-tooltip>

          <span v-if="isActive.table()">
            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="menubar__button"
                  @click="commands.deleteTable"
                  v-bind="attrs"
                  v-on="on"
                >
                  <icon name="delete_table" />
                </button>
              </template>
              <span class="tooltip-font">
                Eliminar tabla
              </span>
            </v-tooltip>

            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="menubar__button"
                  @click="commands.addColumnBefore"
                  v-bind="attrs"
                  v-on="on"
                >
                  <icon name="add_col_before" />
                </button>
              </template>
              <span class="tooltip-font">
                Insertar columna a la izquiera
              </span>
            </v-tooltip>

            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
              <button
                class="menubar__button"
                @click="commands.addColumnAfter"
                v-bind="attrs"
                v-on="on"
              >
                <icon name="add_col_after" />
              </button>
              </template>
              <span class="tooltip-font">
                Insertar columna a la derecha
              </span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="menubar__button"
                  @click="commands.deleteColumn"
                  v-bind="attrs"
                  v-on="on"
                >
                  <icon name="delete_col" />
                </button>
              </template>
              <span class="tooltip-font">
                Eliminar Columna
              </span>
            </v-tooltip>

            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="menubar__button"
                  @click="commands.addRowBefore"
                  v-bind="attrs"
                  v-on="on"
                >
                  <icon name="add_row_before" />
                </button>
              </template>
              <span class="tooltip-font">
                Insertar fila arriba
              </span>
            </v-tooltip>

            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="menubar__button"
                  @click="commands.addRowAfter"
                  v-bind="attrs"
                  v-on="on"
                >
                  <icon name="add_row_after" />
                </button>
              </template>
              <span class="tooltip-font">
                Insertar fila debajo
              </span>
            </v-tooltip>

            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="menubar__button"
                  @click="commands.deleteRow"
                  v-bind="attrs"
                  v-on="on"
                >
                  <icon name="delete_row" />
                </button>
              </template>
              <span class="tooltip-font">
                Eliminar fila
              </span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="menubar__button"
                  @click="commands.toggleCellMerge"
                  v-bind="attrs"
                  v-on="on"
                >
                  <icon name="combine_cells" />
                </button>
              </template>
              <span class="tooltip-font">
                Combinar celdas
              </span>
            </v-tooltip>
          </span>
        </div>
      </editor-menu-bar>

      <editor-content class="editor__content content-editor" :editor="editor"  />
    </div>
    <p class="help">Para conectar una pregunta con opciones de respuesta lógica escriba @</p>

    <!-- Suggestions Container  -->
    <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedUserIndex === index }"
          @click="selectUser(user)"
        >
          {{ user.name }}
        </div>
      </template>
      <div v-else class="suggestion-list__item is-empty">
        No hay preguntas asociadas
      </div>
    </div>
  </div>
</template>

<script>

/* Components */
import FileUploader from '@/components/Base/FileUploader'
import Icon from '@/components/Base/Icon'

/* Libraries */
import Fuse from 'fuse.js'
import tippy, { sticky } from 'tippy.js'

/* Custom plugins tiptap */
import AlignText from '@/plugins/tiptap/aligntext.plugin'
import OrderedList from '@/plugins/tiptap/orderedList.plugin'
import OrderedListLetter from '@/plugins/tiptap/orderedListLetter.plugin'
import OrderedListRoman from '@/plugins/tiptap/orderedListRoman.plugin'
import Mention from '@/plugins/tiptap/customMention.plugin'

/* Bus */
import Bus from '@/helpers/bus'

/* Tiptap */
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ContractTemplateEditor',
  components: {
    EditorContent,
    EditorMenuBar,
    Icon,
    FileUploader
  },

  props: {
    questions: {
      required: true,
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      alignmentItems: [
        {
          align: 'left',
          icon: 'text-left',
          text: 'Alinear a la izquierda'
        },
        {
          align: 'center',
          icon: 'text-center',
          text: 'Alinear al centro'
        },
        {
          align: 'right',
          icon: 'text-right',
          text: 'Alinear a la derecha'
        },
        {
          align: 'justify',
          icon: 'justify',
          text: 'Justificar'
        }
      ],
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new AlignText(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new OrderedListLetter(),
          new OrderedListRoman(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Mention({
            items: this.questions,
            // is called when a suggestion starts
            onEnter: ({ items, query, range, command, virtualNode }) => {
              this.query = query
              this.filteredUsers = items
              this.suggestionRange = range
              this.renderPopup(virtualNode)
              // we save the command for inserting a selected mention
              // this allows us to call it inside of our custom popup
              // via keyboard navigation and on click
              this.insertMention = command
            },
            // is called when a suggestion has changed
            onChange: ({ items, query, range, virtualNode }) => {
              this.query = query
              this.filteredUsers = items
              this.suggestionRange = range
              this.navigatedUserIndex = 0
              this.renderPopup(virtualNode)
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              // reset all saved values
              this.query = null
              this.filteredUsers = []
              this.suggestionRange = null
              this.navigatedUserIndex = 0
              this.destroyPopup()
            },
            // is called on every keyDown event while a suggestion is active
            onKeyDown: ({ event }) => {
              if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
              }

              if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
              }

              if (event.key === 'Enter') {
                this.enterHandler()
                return true
              }

              return false
            },
            // is called when a suggestion has changed
            // this function is optional because there is basic filtering built-in
            // you can overwrite it if you prefer your own filtering
            // in this example we use fuse.js with support for fuzzy search
            onFilter: (items, query) => {
              items = this.questions
              if (!query) {
                return items
              }

              const fuse = new Fuse(items, {
                threshold: 0.2,
                keys: ['name']
              })

              return fuse.search(query).map(item => item.item)
            }
          })
        ],
        content: this.initialContent
      }),
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {},
      dialog: false,
      initialContent: ''
    }
  },

  computed: {
    ...mapState('template', ['currentTemplate']),
    hasResults () {
      return this.filteredUsers.length
    },

    showSuggestions () {
      return this.query || this.hasResults
    },

    activeAlignment () {
      const align = this.editor.activeMarkAttrs.aligntext.align || 'left'
      return this.alignmentItems.filter(v => v.align === align, this)[0]
    }
  },

  created () {
    this.editor.setContent(this.currentTemplate.content.editorContent)
    this.editor.on('update', ({ getHTML }) => {
      this.setEditorContent({ content: getHTML() })
    })
    Bus.$on('reset-editor-default', () => {
      this.editor.focus()
      this.editor.clearContent()
    })
  },

  mounted () {
    this.editor.focus()
  },

  methods: {
    ...mapActions('template', [
      'getContentEditorByFile'
    ]),
    ...mapMutations('template', {
      setEditorContent: 'SET_EDITOR_CONTENT'
    }),
    // navigate to the previous item
    // if it's the first item, navigate to the last one
    upHandler () {
      this.navigatedUserIndex = ((this.navigatedUserIndex + this.filteredUsers.length) - 1) % this.filteredUsers.length
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler () {
      this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length
    },
    enterHandler () {
      const user = this.filteredUsers[this.navigatedUserIndex]
      if (user) {
        this.selectUser(user)
      }
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectUser (user) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.id,
          label: user.name
        }
      })
      this.editor.focus()
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderPopup (node) {
      if (this.popup) {
        return
      }
      // ref: https://atomiks.github.io/tippyjs/v6/all-props/

      tippy('.main', {
        getReferenceClientRect: node.getBoundingClientRect,
        appendTo: () => document.body,
        interactive: true,
        sticky: true, // make sure position of tippy is updated when content changes
        plugins: [sticky],
        content: this.$refs.suggestions,
        trigger: 'mouseenter', // manual
        showOnCreate: true,
        theme: 'primary',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200]
      })
    },
    destroyPopup () {
      if (this.popup) {
        this.popup[0].destroy()
        this.popup = null
      }
    },

    uploadFile (data) {
      this.$refs.uploader.loading(true)
      this.getContentEditorByFile({ files: data })
        .then(response => {
          this.$refs.uploader.loading(true)
          this.dialog = false
          this.$refs.uploader.cleanFiles()
          this.editor.setContent(response.data.content)
          this.$refs.uploader.loading(false)
        })
        .catch(error => {
          this.$refs.uploader.loading(true)
          this.$refs.uploader.cleanFiles()
          this.$refs.uploader.loading(false)
          this.dialog = false
          console.log(error)
        })
    },

    clearEditor () {
      this.editor.clearContent()
    }
  },

  beforeDestroy () {
    this.destroyPopup()
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
@import "~@/assets/css/main";

.heading-font {
  font-size: 13px;
  width: 17px;
  height: 17px;
  text-align: center;
}

.main {
  height: 100%;
}

.editor-menu {
  .v-menu__content {
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: none;
  }
}

.editor-menu__list {
  margin: 0;
  padding: 0;

  .v-list-item {
    margin: 0;
    padding: 0;

    button {
      height: 100%;
      width: 100%;
    }
  }

  .v-list-item:hover {
    // border: 1px solid blue !important;
    background: $color-bg;
  }
}

.tooltip-font {
  font-size: 13px;
  font-weight: bold;
}

.editor {
  height: 95%;
  max-width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 15px 30px 15px !important;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 150%;
    color: black;
  }

  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 150%;
    color: black;
  }

  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 150%;
    color: black;
  }

  h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 150%;
    color: black;
  }
}

.content-editor {
  height: 95%;
  overflow-y: auto;
  padding: 5px;
}

.help {
  font-size: 13px;
  margin-top: 8px;
  color: #7E7E7E;
}

.mention {
  background: $color-bg;
  color: rgba($color-black, 0.7);
  font-size: 0.6rem;
  font-weight: 600;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

.mention-suggestion {
  color: rgba($color-black, 0.6);
}

.suggestion-list {
  padding: 0.2rem;
  // border: 2px solid rgba($color-black, 0.1);
  color: black;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 400;
  max-height: 250px;
  overflow-y: auto;

  &__no-results {
    padding: 0.2rem 0.5rem;
  }

  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.is-selected,
    &:hover {
      background: $color-bg;
      color: black;
    }

    &.is-empty {
      opacity: 0.5;
    }
  }
}

.tippy-box[data-theme~="primary"] {
  background-color: $color-white;
  padding: 0;
  font-size: 1rem;
  text-align: inherit;
  color: $color-black;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

</style>
