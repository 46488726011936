<template>
  <div>
    <v-navigation-drawer
      v-model="showDrawer"
      absolute
      bottom
      temporary
      :right="true"
      @input="changeState"
    >
    <v-row no-gutters class="scroll-detail-obs hide-scroll">
      <div v-if="historyList.length > 0">
        <div class="my-2" v-for="history in historyList" :key="history.idobservation">
          <v-divider />
          <v-row class="mt-3" no-gutters>
            <v-col class="px-2 py-0" cols="12" sm="12" md="12">
              <span> {{ history.field }} </span>
            </v-col>
            <v-col class="px-2 py-0" cols="12" sm="12" md="12" v-if="history.show">
              <span> Anterior: {{ history.oldValue }} </span>
            </v-col>
            <v-col class="px-2 py-0" cols="12" sm="12" md="12"  v-if="history.show">
              <span> Actual: {{ history.newValue }} </span>
            </v-col>
            <v-col class="px-2 py-0" cols="12" sm="12" md="12">
              <span class="sub-info"> Tipo: {{ history.type }} </span>
            </v-col>
            <v-col class="px-2 py-0" cols="12" sm="12" md="12">
              <span class="sub-info"> Por {{ history.usermod }} - {{ history.updated }} </span>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else>
        <v-row>
          <v-col class="ml-3" cols="12" sm="12" md="12">
              <span> No hay cambios en esta plantilla </span>
            </v-col>
          </v-row>
      </div>
    </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import { colors } from '@/utils/colors'
// /* Bus */
// import Bus from '@/helpers/bus'

export default {
  name: 'History',
  components: {},
  props: {
    // id: {
    //   type: Number,
    //   default: 0
    // },
    // showNavegationDrawer: {
    //   type: Boolean,
    //   default: () => false
    // }
  },
  data: () => ({
    group: null,
    showDrawer: false,
    idtemplate: 0,
    textArea: '',
    addObservationLoader: false,
    historyList: []
  }),
  watch: {
    // showNavegationDrawer () {
    //   this.showDrawer = this.showNavegationDrawer
    // }
  },
  created () {
    // Bus.$on('show-contract-observations', () => {
    //   this.showDrawer = this.drawer
    //   this.obtainObservations()
    // })
    // this.obtainTemplateHistory()
  },
  computed: {
    // ...mapState('contract', ['drawer'])
  },
  methods: {
    // ...mapMutations('contract', { setDrawer: 'SET_DRAWER' }),
    // ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),
    ...mapActions('template', ['getTemplateHistory']),

    changeState (event) {
      this.showdrawer = event
      if (!event) {
        this.$emit('update-show-navegation-drawer', event)
        this.historyList = []
      }
    },

    obtainTemplateHistory () {
      const request = {
        idtemplate: this.idtemplate
      }
      this.getTemplateHistory(request)
        .then(response => {
          this.historyList = response.data
          this.showDrawer = true
        })
    },
    callFromParent (id) {
      this.idtemplate = id
      this.obtainTemplateHistory()
    }
  }
}
</script>

<style>
.center-btn {
  text-align: center;
}
.scroll-detail-obs {
  min-height: 200px;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
