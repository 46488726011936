import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-bg content-container"},[_c(VRow,{attrs:{"justify":"center","no-gutters":""}},[_c(VCol,{attrs:{"cols":"5","md":"10","lg":"7"}},[_c(VTabs,{attrs:{"fixed-tabs":"","hide-slider":"","height":"42"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabsItem),function(tab){return _c(VTab,{key:tab.text,class:{
            'border-left':  _vm.isQuestions(tab.text),
            'border-right': !_vm.isQuestions(tab.text)
          },attrs:{"disabled":_vm.getTabEditorDisable(tab.text)}},[_vm._v(" "+_vm._s(tab.text)+" ")])}),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{staticClass:"tab-content main-bg"},[_c('ContractTemplateQuestionnaire',{attrs:{"questions":_vm.content.questionnaire.questions}})],1),_c(VTabItem,{staticClass:"tab-content main-bg"},[_c('div',{staticClass:"editor-container"},[_c('ContractTemplateEditor',{ref:"editor",attrs:{"questions":_vm.getEditorQuestions}})],1)])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }