import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{staticClass:"detail-container fill-height"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"md":"auto"}},[_c(VBtn,{attrs:{"icon":"","color":"black","to":"/portal/templates","exact":""}},[_c('img',{attrs:{"src":require("@/assets/icons/ic_keyboard_normal.svg"),"alt":""}})])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('p',{staticClass:"detail-header mb-0"},[_vm._v(" Plantilla, "+_vm._s(_vm.template.template.contracts)+" contratos generados ")]),_c('p',{staticClass:"mt-0 description"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c(VSpacer),(_vm.template.template.published)?_c(VCol,{staticClass:"mr-15",attrs:{"cols":"auto"}},[_c('ContractGenerationDialog',{attrs:{"generateFromTemplate":true,"template":this.template}},[_c('template',{slot:"text"},[_vm._v(" Usar Plantilla ")])],2)],1):_vm._e()],1),_c('div',{staticClass:"detail-content px-2"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.template.template.title)+" ")]),_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.getVisualizationContent(_vm.id)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/eye.svg"),"height":"20","width":"20"}})])]}}],null,false,2060027673)},[_c('span',[_vm._v("Previsualizar")])])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.convertFile(_vm.id)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/download.svg"),"height":"20","width":"20"}})])]}}],null,false,3280416221)},[_c('span',[_vm._v("Descargar")])])],1),(_vm.isLegalProfile())?_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ContractTemplateCreationDialog',{attrs:{"is-icon":true,"is-editing":true,"id-template":_vm.id,"template":_vm.template.template}},[_c('template',{slot:"text"},[_c('img',_vm._g(_vm._b({attrs:{"src":require("@/assets/icons/edit.svg"),"height":"20","width":"20"}},'img',attrs,false),on))])],2)]}}],null,false,1791342370)},[_c('span',[_vm._v("Editar")])])],1):_vm._e(),(_vm.isLegalProfile())?_c(VCol,{attrs:{"cols":"auto"}},[(_vm.template.template.published)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.publicToggle(_vm.id, _vm.template.template.published)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/public_on.svg"),"height":"20","width":"20"}})])]}}],null,false,313539360)},[_c('span',[_vm._v("Ocultar")])]):_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.publicToggle(_vm.id, _vm.template.template.published)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/public_off.svg"),"height":"20","width":"20"}})])]}}],null,false,2008469294)},[_c('span',[_vm._v("Publicar")])])],1):_vm._e(),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","exact":"","color":"black"},on:{"click":function($event){return _vm.historyFunction(_vm.template.template)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-history")])],1)]}}],null,false,3509431899)},[_c('span',[_vm._v("Historial")])])],1)],1)],1)],1)],1),_c('div',{staticClass:"detail-attached"},[_c('p',{staticClass:"subtitle-1 attached-header"},[_vm._v("Anexos")]),_vm._l((_vm.template.attachments),function(attachment){return _c('div',{key:attachment.title},[_c(VRow,{staticClass:"detail-attached-header",attrs:{"no-gutters":""}},[_c(VCol,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(attachment.title)+" ")]),_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.getVisualizationContent(attachment.idtemplate)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/eye.svg"),"height":"20","width":"20"}})])]}}],null,true)},[_c('span',[_vm._v("Previsualizar")])])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.convertFile(attachment.idtemplate)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/download.svg"),"height":"20","width":"20"}})])]}}],null,true)},[_c('span',[_vm._v("Descargar")])])],1),(_vm.isLegalProfile())?_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ContractTemplateCreationDialog',{attrs:{"is-icon":true,"is-editing":true,"is-attached":true,"id-template":attachment.idtemplate.toString()}},[_c('template',{slot:"text"},[_c('img',_vm._g(_vm._b({attrs:{"src":require("@/assets/icons/edit.svg"),"height":"20","width":"20"}},'img',attrs,false),on))])],2)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1):_vm._e(),(_vm.isLegalProfile())?_c(VCol,{attrs:{"cols":"auto"}},[(attachment.published)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.publicToggle(attachment.idtemplate, attachment.published )}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/public_on.svg"),"height":"20","width":"20"}})])]}}],null,true)},[_c('span',[_vm._v("Ocultar")])]):_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.publicToggle(attachment.idtemplate, attachment.published )}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/public_off.svg"),"height":"20","width":"20"}})])]}}],null,true)},[_c('span',[_vm._v("Publicar")])])],1):_vm._e(),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","exact":"","color":"black"},on:{"click":function($event){return _vm.historyFunction(attachment)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v("Historial")])])],1)],1)],1)],1)],1)}),(_vm.isLegalProfile())?_c('ContractTemplateCreationDialog',{attrs:{"is-text":true,"is-attached":true,"contract-template":_vm.template.template,"is-creating-attach":true}},[_c('template',{slot:"text"},[_vm._v(" + Agregar anexos ")])],2):_vm._e()],2),_c('div',{staticClass:"detail-addendum"},[_c('p',{staticClass:"subtitle-1 addendum-header"},[_vm._v("Adendas")]),_vm._l((_vm.template.addendums),function(addendum){return _c('div',{key:addendum.title},[_c(VRow,{staticClass:"detail-addendum-header",attrs:{"no-gutters":""}},[_c(VCol,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(addendum.title)+" ")]),_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.getVisualizationContent(addendum.idtemplate)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/eye.svg"),"height":"20","width":"20"}})])]}}],null,true)},[_c('span',[_vm._v("Previsualizar")])])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.convertFile(addendum.idtemplate)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/download.svg"),"height":"20","width":"20"}})])]}}],null,true)},[_c('span',[_vm._v("Descargar")])])],1),(_vm.isLegalProfile())?_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ContractTemplateCreationDialog',{attrs:{"is-icon":true,"is-editing":true,"is-addendum":true,"id-template":addendum.idtemplate.toString()}},[_c('template',{slot:"text"},[_c('img',_vm._g(_vm._b({attrs:{"src":require("@/assets/icons/edit.svg"),"height":"20","width":"20"}},'img',attrs,false),on))])],2)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1):_vm._e(),(_vm.isLegalProfile())?_c(VCol,{attrs:{"cols":"auto"}},[(addendum.published)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.publicToggle(addendum.idtemplate, addendum.published )}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/public_on.svg"),"height":"20","width":"20"}})])]}}],null,true)},[_c('span',[_vm._v("Ocultar")])]):_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"black","exact":""},on:{"click":function($event){return _vm.publicToggle(addendum.idtemplate, addendum.published )}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/public_off.svg"),"height":"20","width":"20"}})])]}}],null,true)},[_c('span',[_vm._v("Publicar")])])],1):_vm._e(),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","exact":"","color":"black"},on:{"click":function($event){return _vm.historyFunction(addendum)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v("Historial")])])],1)],1)],1)],1)],1)}),(_vm.isLegalProfile())?_c('ContractTemplateCreationDialog',{attrs:{"is-text":true,"is-addendum":true,"contract-template":_vm.template.template,"is-creating-attach":true}},[_c('template',{slot:"text"},[_vm._v(" + Agregar adendas ")])],2):_vm._e()],2),_c('ContractTemplateVisualizationDialog',{attrs:{"dialog":_vm.dialogVisualization},on:{"close":function($event){_vm.dialogVisualization = false}}},[_c('template',{slot:"content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.currentVisualization)}})])],2),_c('HistoryNavegationDrawer',{ref:"refHistory",on:{"update-show-navegation-drawer":function($event){_vm.showNavegationDrawer = false}}})],1):_c('div',[_c('Loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }