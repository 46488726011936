<template>
  <div class="questionnaire">
    <div class="question-container mt-6" v-for="question in questions" :key="question.id">
      <v-form v-model="question.isValid">
        <v-row>
          <v-col cols="auto">
            <label class="question-label">Tipo de respuesta</label>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="dark"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense flat>
                <v-list-item>
                  <v-btn
                    small
                    text
                    class="question-option"
                    @click="removeQuestion(question)"
                  >
                    Borrar
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    small
                    text
                    class="question-option"
                    @click="duplicateQuestion(question)"
                  >
                    Duplicar
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-select
          :items="questionTypes"
          solo
          dense
          outlined
          :value="question.typeQuestion"
          v-model="question.typeQuestion"
          :rules="[rules.typeRequired]"
          flat
        />
        <label class="question-label">Agregar pregunta</label>
        <v-text-field v-model="question.name" :rules="getQuestionRules(question)" :value="question.name" class="mt-3" solo dense flat outlined />
        <div v-if="isListQuestion(question)">
          <label class="question-label">Opciones de respuesta</label>
          <v-row align="center">
            <v-col cols="auto" v-for="item in question.answerOptions" :key="item.value">
              <div class="option">
                <v-chip close large label outlined close-icon="mdi-close" @click:close="removeOptionList({question, option: item})">
                  {{ item.value }}
                </v-chip>
              </div>
            </v-col>
            <v-col cols="auto">
              <v-btn v-if="!question.isAdding" text color="primary" class="add-question" @click="changeAddingOption({question, value: true})">+Agregar opción</v-btn>
              <v-text-field v-else @keydown.enter="addOptionList(question)" autofocus @keydown.esc="changeAddingOption({question, value: false})" hide-details solo outlined flat clearable v-model="question.newOption" />
            </v-col>
          </v-row>
        </div>
        <div>
          <p class="question-hint">
            {{ getQuestionHint(question.type) }}
          </p>
        </div>
      </v-form>
    </div>
    <v-btn text color="primary" class="add-question mt-3" @click="addQuestion">+Agregar pregunta</v-btn>
  </div>
</template>

<script>

/* Utils */
import { questionHints } from '@/utils/questionHints'
import { mapMutations } from 'vuex'

export default {
  name: 'ContractTemplateQuestionnaire',
  props: {
    questions: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      type: 'text',
      rules: {
        typeRequired: v => !!v || 'El tipo de pregunta es requerido',
        required: v => !!v || 'El texto de la pregunta es requerido',
        leastOneOption: (v, questionOptions) => {
          return questionOptions.length > 0 || 'La pregunta requiere que se agregue al menos una opción de respuesta'
        },
        max: (v) => (v && v.length <= 150) || 'La pregunta no puede tener más de 150 caracteres'
      },
      questionTypes: [
        {
          text: 'Texto',
          value: 'text'
        },
        {
          text: 'Lista',
          value: 'list'
        },
        {
          text: 'Fecha',
          value: 'date'
        },
        {
          text: 'Numérica',
          value: 'number'
        }
      ]
    }
  },

  watch: {
    questions: {
      handler () {
        this.setValidQuestionnaire({ isValid: this.validateAllQuestions() })
      },
      deep: true
    }
  },

  computed: {
    emptyList () {
      return this.questions.length === 0
    }
  },

  methods: {
    ...mapMutations('template', {
      setQuestionnaire: 'SET_QUESTIONNAIRE',
      addQuestion: 'ADD_QUESTION',
      removeQuestion: 'REMOVE_QUESTION',
      duplicateQuestion: 'DUPLICATE_QUESTION',
      changeAddingOption: 'CHANGE_ADDING_OPTION',
      addOptionList: 'ADD_OPTION_LIST',
      removeOptionList: 'REMOVE_OPTION_LIST',
      setValidQuestionnaire: 'SET_VALID_QUESTIONNAIRE'
    }),

    isListQuestion (question) {
      return question.typeQuestion === 'list'
    },

    getQuestionHint (questionType) {
      return questionHints[questionType]
    },

    getQuestionRules (question) {
      const finalRules = this.rules.required
      const max = this.rules.max
      return question.typeQuestion === 'list' ? [finalRules, this.rules.leastOneOption(question.name, question.answerOptions), max] : [finalRules, max]
    },

    validateAllQuestions () {
      for (let index = 0; index < this.questions.length; index++) {
        const element = this.questions[index]
        if (!element.isValid) {
          return false
        }
      }

      return true
    }
  }
}

</script>

<style lang="scss">

.questionnaire {
  margin-bottom: 80px;

  .v-chip {
    border: 1px solid rgba(0, 0, 0, 0.42);
    font-weight: 500;
  }

  .question-hint {
    color: #7E7E7E;
    font-size: 13px;
  }
}

.question-container {
  background: white;
  border-radius: 8px;
  padding: 10px 20px;

  .question-label {
    font-weight: 500;
    color: var(--v-dark-base);
  }
}

.add-question {
  text-transform: none;
  margin: 0 !important;
  padding: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
}

.question-option {
  text-transform: none;
}

</style>
